import { gql, useMutation } from '@apollo/client';

import {
  RotaryMagazineEmailMutation,
  RotaryMagazineEmailMutationVariables,
} from '@typings/operations';

export const mutation = gql`
  mutation rotaryMagazineEmail(
    $rotaryMagazineForm: rotaryMagazineFormInput!
    $langcode: String!
  ) {
    rotaryMagazineEmail(
      rotaryMagazineForm: $rotaryMagazineForm
      langcode: $langcode
    )
  }
`;

export const useRotaryMagazineEmailMutation = () =>
  useMutation<
    RotaryMagazineEmailMutation,
    RotaryMagazineEmailMutationVariables
  >(mutation);
