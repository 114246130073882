import React, { useEffect, useRef, useState } from 'react';

import Button from '@rotaryintl/harris-button';
import Checkbox from '@rotaryintl/harris-form-basic/dist/CheckBox/Checkbox';
import Select from '@rotaryintl/harris-form-basic/dist/SelectField/Select';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import Loading from '@components/Loading';

import createValidationSchema from './RotaryMagazineValidationSchema';
import './styles.css';

import { RotaryMagazineFormType } from '@domain/rotary-magazine/types';

import { useRecaptcha } from '@use-cases/account/hook';

import { useDISCountries, useDISStates } from '@repositories/disCountry';

import { getSortedCountries } from '@utils/getSortedCountries';
import { getLanguageFromURL } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

import { Country, State } from '@typings/graphql';

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

interface RotaryMagazineFormProps {
  onErrorsChange: (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>,
    submitted?: boolean
  ) => void;
  handleFormSubmit: (rotaryMagazineFormValues: RotaryMagazineFormType) => void;
}

interface InitialValues {
  email: string;
  'first-name': string;
  'last-name': string;
  address: string;
  city: string;
  'club-name': string;
  'country-dropdown': string;
  'state-dropdown': string;
  'postal-code': string;
  'digital-version-checkbox': string[];
  'address-second-line'?: string;
  'address-third-line'?: string;
  'member-id': string;
}

interface StateSelectProps {
  countryId: string;
  onChange: Function;
}

interface StateCountryOption {
  label: string;
  value: string;
}

const StateSelect: React.FC<StateSelectProps> = ({ countryId, onChange }) => {
  const { t } = useTranslation();

  const states = useDISStates(countryId);

  const [stateOptions, setStateOptions] = useState<StateCountryOption[]>([]);

  const generateStateOptions = (countryId: string, states: State[]) =>
    states
      .filter(s => s.countryId === countryId)
      .filter(
        (s, index, self) => index === self.findIndex(state => state.id === s.id)
      )
      .map(s => ({ label: s.name, value: s.id }));

  useEffect(() => {
    setStateOptions(
      generateStateOptions(countryId, states?.data?.states || [])
    );
  }, [countryId]);

  if (!countryId) return <div />;

  if (!states?.data) {
    return <Loading inline small />;
  }

  if (!states?.data.states.length) {
    return (
      <TextField
        name="state"
        label={t(
          'rotary-magazine-form.stateProvince.label',
          'State/Province/Territory'
        )}
        characters={250}
        className="mb-6"
      />
    );
  }

  return (
    <Select
      name="state-dropdown"
      label={t(
        'rotary-magazine-form.stateProvince.label',
        'State/Province/Territory'
      )}
      placeholder={t('rotary-magazine-form.select.placeholder', 'Select...')}
      options={stateOptions}
      onChange={id => onChange(id)}
      searchable
      required
      clearable
      className="mb-6"
    />
  );
};

const RotaryMagazineForm: React.FC<RotaryMagazineFormProps> = ({
  onErrorsChange,
  handleFormSubmit,
}) => {
  const { t } = useTranslation();

  const countries = useDISCountries();

  const [countryId, setCountryId] = useState<string>('');
  const [countryOptions, setCountryOptions] = useState<StateCountryOption[]>(
    []
  );

  const recaptchaRef = useRef(null);
  const {
    onRecaptchaChange,
    onRecaptchaExpired,
    resetCaptcha,
    token,
  } = useRecaptcha(recaptchaRef);

  const validationSchema = createValidationSchema(t);

  const handleErrorsChange = (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>,
    submitted?: boolean
  ) => {
    onErrorsChange(errors, touched, submitted);
  };

  const initialValues: InitialValues = {
    email: '',
    'first-name': '',
    'last-name': '',
    address: '',
    city: '',
    'club-name': '',
    'country-dropdown': '',
    'state-dropdown': '',
    'postal-code': '',
    'digital-version-checkbox': [],
    'address-second-line': '',
    'address-third-line': '',
    'member-id': '',
  };

  const generateCountryOptions = (countries: Country[]) => {
    return countries.map(c => ({ label: c.name, value: c.id }));
  };

  useEffect(() => {
    setCountryOptions(
      generateCountryOptions(
        getSortedCountries(countries?.data?.countries || [])
      )
    );
  }, [countries?.data?.countries]);

  const [allErrors, setAllErrors] = useState<FormikErrors<InitialValues>>({});
  const [allTouched, setAllTouched] = useState<FormikTouched<InitialValues>>(
    {}
  );

  const prevErrors = usePrevious(allErrors);
  const prevTouched = usePrevious(allTouched);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleErrors = (
    errors: FormikErrors<InitialValues>,
    touched: FormikTouched<InitialValues>
  ) => {
    setAllErrors(errors);
    setAllTouched(touched);
  };

  useEffect(() => {
    if (prevErrors !== allErrors || prevTouched !== allTouched) {
      handleErrorsChange(allErrors, allTouched, submitted);
      setTimeout(() => setSubmitted(false), 1000);
    }
  }, [allErrors, prevErrors, allTouched, prevTouched]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={(values, { setSubmitting }: FormikHelpers<InitialValues>) => {
        const {
          email,
          'first-name': firstName,
          'last-name': lastName,
          address: address1,
          city,
          'club-name': clubName,
          'country-dropdown': country,
          'state-dropdown': state,
          'postal-code': postal,
          'address-second-line': address2,
          'address-third-line': address3,
          'member-id': memberId,
          'digital-version-checkbox': digitalOptIn,
        } = values;

        const rotaryMagazineFormValues: RotaryMagazineFormType = {
          email,
          firstName,
          lastName,
          address1,
          city,
          clubName,
          country,
          state,
          postal,
          address2,
          address3,
          memberId,
          digitalOptIn,
          token,
        };
        handleFormSubmit(rotaryMagazineFormValues);
        setSubmitting(false);
        resetCaptcha();
      }}
    >
      {({
        handleSubmit,
        errors,
        touched,
        setFieldValue,
      }: FormikProps<InitialValues>) => {
        handleErrors(errors, touched);

        return (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              name="email"
              label={t('rotary-magazine-form.email.label', 'Email address')}
              placeholder={t(
                'rotary-magazine-form.email.placeholder',
                'Your email'
              )}
              description={t(
                'rotary-magazine-form.email.description',
                'Email address where you’d like to receive correspondence from Rotary International and GTxcel.'
              )}
              icon="email"
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="first-name"
              label={t('rotary-magazine-form.firstName.label', 'First name')}
              placeholder={t(
                'rotary-magazine-form.firstName.label',
                'First name'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="last-name"
              label={t('rotary-magazine-form.lastName.label', 'Last name')}
              placeholder={t(
                'rotary-magazine-form.lastName.label',
                'Last name'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="address"
              label={t('rotary-magazine-form.address.label', 'Address')}
              placeholder={t('rotary-magazine-form.address.label', 'Address')}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="address-second-line"
              label={t(
                'rotary-magazine-form.addressSecondLine.label',
                'Address, second line'
              )}
              placeholder={t(
                'rotary-magazine-form.addressSecondLine.placeholder',
                'Address, second line (optional)'
              )}
              characters={250}
              className="mb-6"
            />
            <TextField
              name="address-third-line"
              label={t(
                'rotary-magazine-form.addressThirdLine.label',
                'Address, third line'
              )}
              placeholder={t(
                'rotary-magazine-form.addressThirdLine.placeholder',
                'Address, third line (optional)'
              )}
              characters={250}
              className="mb-6"
            />
            <Select
              name="country-dropdown"
              label={t(
                'rotary-magazine-form.countryDropdown.label',
                'Country/Region'
              )}
              placeholder={t(
                'rotary-magazine-form.countryDropdown.label',
                'Country/Region'
              )}
              options={countryOptions}
              onChange={id => {
                setFieldValue('state', '');
                setFieldValue('state-dropdown', '');
                setCountryId(id);
                setFieldValue('country-dropdown', id);
              }}
              required
              clearable
              searchable
              className="mb-6"
            />
            <TextField
              name="city"
              label={t('rotary-magazine-form.city.label', 'City')}
              placeholder={t('rotary-magazine-form.city.label', 'City')}
              characters={250}
              required
              className="mb-6"
            />
            <StateSelect
              countryId={countryId}
              onChange={(id: string) => {
                setFieldValue('state-dropdown', id);
              }}
            />
            <TextField
              name="postal-code"
              label={t('rotary-magazine-form.postalCode.label', 'Postal code')}
              placeholder={t(
                'rotary-magazine-form.postalCode.label',
                'Postal code'
              )}
              characters={250}
              required
              className="mb-6"
            />
            <TextField
              name="club-name"
              label={t('rotary-magazine-form.clubName.label', 'Club name')}
              placeholder={t(
                'rotary-magazine-form.clubName.label',
                'Club name'
              )}
              characters={250}
              required
              notice={
                <>
                  {t(
                    'rotary-magazine-form.clubName.contactText',
                    `Contact <a href="data@rotary.org">data@rotary.org</a> if you don't have a club.`
                  )}
                </>
              }
              className="mb-6"
            />
            <TextField
              name="member-id"
              label={t('rotary-magazine-form.memberId.label', 'Member ID')}
              placeholder={t(
                'rotary-magazine-form.memberId.placeholder',
                'Your member ID'
              )}
              characters={250}
              className="mb-6"
            />
            <Checkbox
              name="digital-version-checkbox"
              options={[
                {
                  label: (
                    <>
                      {t(
                        'rotary-magazine-form.digital.version',
                        'I elect to receive <em>Rotary</em> magazine in digital form instead of the print edition.*'
                      )}
                    </>
                  ),
                  readonly: false,
                  value: '0',
                },
              ]}
              required
              className="mb-6"
            />
            <p>
              {t(
                'rotary-magazine-form.privacyStatement',
                `Your privacy is important to Rotary and the personal data you share with Rotary will only be used for official Rotary business. This means that your personal data will be used to facilitate your subscription to the Rotary digital magazine. Personal data you provide when subscribing to the digital magazine may be transferred to Rotary service providers, including the provider of the digital magazine. You may receive information about your subscription from the magazine provider via email. Personal information collected is subject to <a href="data@rotary.org">Rotary’s Privacy policy</a>. If you have any questions about the use of your personal data, you may contact Data Services, <a href="data@rotary.org">data@rotary.org</a>.`
              )}
            </p>
            <ReCAPTCHA
              hl={getLanguageFromURL()}
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
              onChange={onRecaptchaChange}
              onExpired={onRecaptchaExpired}
            />
            <section className="flex-1 mt-12">
              <Button
                variant="primary"
                disabled={false}
                clickHandler={() => {
                  setSubmitted(true);
                  handleSubmit();
                }}
                className="mr-6"
              >
                {t('reset-password.form.submit-label', 'Submit')}
              </Button>
              <Button variant="text" disabled={false}>
                {t('edit-membership.form.cancel-button', 'Cancel')}
              </Button>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RotaryMagazineForm;
